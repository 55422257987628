.video-controls {
  display: none;
  transition: transform .5s ease-in-out; }

@keyframes sound {
  0% {
    height: 27px; }
  50% {
    height: 8px; }
  100% {
    height: 27px; } }
