.play-btn {
  width: 205px;
  height: 205px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 21;
  background: radial-gradient(50% 50% at 50% 50%, rgba(79, 176, 242, 0.65) 0%, rgba(4, 20, 63, 0) 100%); }
  @media (max-width: 1600px) {
    .play-btn {
      width: 150px;
      height: 150px; } }
  .play-btn svg {
    transition: opacity .3s ease-in-out;
    width: 100%;
    height: 100%; }
  .play-btn:hover svg {
    opacity: .9; }
