@import "../../assets/scss/utils/grid.scss";
@import "../../assets/scss/utils/colors.scss";

.mobile-placeholder {
	position: fixed;
	z-index: 1000;
	background-color: #034f8c;
	background-repeat: no-repeat;
	background-size: cover;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;

	// @media screen and (orientation:portrait) and (max-width: 640px) {
	// 	display: flex;
	// }
	//@include xxl {
	//	display: flex;
	//}

	@media (min-width: 1920px) and (orientation: landscape) {
		display: none;
	}

	&__inner {
		padding: 0 50px;
		@include xs {
			padding: 0 20px;
		}
	}

	&__text {
		padding: 0 60px;
		font-size: 48px;
		line-height: 1.6em;
		text-align: center;
		@include xl {
			font-size: 46px;
		}
		@include lg {
			font-size: 42px;
		}
		@include md {
			font-size: 36px;
			padding: 0 40px;
		}
		@include sm {
			font-size: 24px;
			padding: 0 20px;
		}
		@include xs {
			padding: 0;
			font-size: 22px;
		}
	}

	&__logo {
		// background-image: url(../../assets/img/logo2.png);
		background-repeat: no-repeat;
		background-size: 100%;
		width: 540px;
		height: 220px;
		margin: 0 auto 70px;
		@include xl {
			width: 500px;
			height: 210px;
		}
		@include lg {
			width: 480px;
			height: 200px;
		}
		@include md {
			width: 420px;
			height: 160px;
			margin-bottom: 60px;
		}
		@include sm {
			width: 300px;
			height: 120px;
			margin-bottom: 50px;
		}
		@include xs {
			width: 270px;
			height: 110px;
		}
		@include xxs {
			width: 200px;
			height: 80px;
		}
	}
}
